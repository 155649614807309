<template>
    <div class="message-text">
        <div class="open-chat">
            <div class="noti-title">
                <img class="m-r-8" src="@/assets/images/icons/hello.png" width="24px" height="24px" />
                <span>반갑습니다:)</span>
            </div>
            <div class="noti-body">앞으로 {{ content.name }}님을 담당할 매니저 에일리입니다 😊</div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ActionOpenChatAI',
    props: ['message'],
    methods: {
        async onClickBtn() {
            const chat = this.$store.getters.chats.find(chat => chat.agent_id === 7 || chat.agent_id === 4)
            try {
                await this.$store.dispatch('loadChat', {
                    chatId: chat.id,
                    force: true,
                })
            } catch (e) {
                console.log(e)
            }
        },
    },
    computed: {
        content() {
            return this.$mustParse(this.message.content)
        },
    },
}
</script>

<style scoped lang="scss">
.open-chat {
    padding: 9px 4px;
    .noti-title {
        @include spoqa-f-bold;
        font-size: 17px;
        color: #111111;
        padding: 0 2px;
        margin-bottom: 8px;
        display: flex;
        align-items: center;
    }
    .noti-body {
        font-size: 14px;
        margin-top: 8px;
        color: #111111;
        line-height: 26px;
    }
}
</style>
